<script setup lang="ts">
import moment from 'moment/moment'
import 'moment/locale/fr.js'
import 'moment/locale/en-gb'
import useIsDesktop from '~/composables/useIsDesktop'
import { search } from '~/utils/search'
import { formatQueryToTenderBody } from '~/utils/formatter'

defineProps({
  destinationsContent: {
    type: Object,
    required: true,
  },
})

const { t } = useI18n()
const displaySearchItem = ref('')
const { isDesktop } = useIsDesktop()
const availableCruiselines = ref([])
const availableDestinations = ref([])
const availableDepartureDate = ref([])

const date = ref([])
const selectedDestinations = ref([])
const selectedCruiselines = ref([])
const firstTenderCall = ref(true)
const dynamicQueryFormat = computed(() => {
  const query = {
    'allDestinations.name': selectedDestinations.value,
    'ship.cruiseline.name': selectedCruiselines.value,
  }

  if (typeof date.value[0] !== 'undefined') {
    query['sailings.hits.departureDateFrom'] = [moment(date.value[0]).format('yyyy-MM-DD'), moment(date.value[1]).format('yyyy-MM-DD')]
  }

  return query
})

const dynamicFilter = async () => {
  const res = await search(formatQueryToTenderBody(dynamicQueryFormat.value, {}), 1, firstTenderCall.value)
  firstTenderCall.value = false

  availableCruiselines.value = res['hydra:search']['hydra:facet']['ship.cruiseline.name']
  availableDestinations.value = res['hydra:search']['hydra:facet']['destinations.name']
  availableDepartureDate.value = res['hydra:search']['hydra:facet']['sailings.hits'].departureDate.buckets.filter(item => item.doc_count > 0).map(item => item.key_as_string)
}

dynamicFilter();

const nextFilter = () => {
  if (displaySearchItem.value == 'navigation') {
    displaySearchItem.value = 'travel'
  }
  else if (displaySearchItem.value == 'travel') {
    displaySearchItem.value = 'company'
  }
}

function resetSearch(type = 'all') {
  if (type == 'all' || type == 'destinations') {
    selectedDestinations.value = []
  }

  if (type == 'all' || type == 'date') {
    date.value = []
  }

  if (type == 'all' || type == 'cruiselines') {
    selectedCruiselines.value = []
  }

  dynamicFilter()
}

watch (selectedDestinations, async (nextObj, prevObj) => {
  if (Object.values(prevObj).length > Object.values(nextObj).length) {
    await dynamicFilter()
  }
}, { deep: true })

const handleClickOutside = (event) => {
  if (event.target.className === 'see-more-cta') {
    return
  }

  if (displaySearchItem.value) {
    displaySearchItem.value = ''
  }
}

const formatDate = (data: any) => {
  if (!Array.isArray(data)) {
    data = [
      data,
      data,
    ]
  }

  return t('search.travel.value.start') + moment(data[0]).format('DD/MM/YYYY') + t('search.travel.value.end') + moment(data[1]).format('DD/MM/YYYY')
}

watch(displaySearchItem, async (nextObj, prevObj) => {
  if (prevObj !== '') {
    dynamicFilter()
  }
})
</script>

<template>
  <div
    v-if="!isDesktop"
    class="search-container search-container-mobile"
  >
    <UiElementsButtonModal
      :modal-id="'modal-search'"
      :has-align-btn-close="true"
      :padding-content="'padding-none'"
    >
      <template #button-modal>
        <div
          class="search-btn search-item"
          @click="displaySearchItem = 'mobile-modal'"
        >
          <div class="info">
            <span class="info-title">
              {{ $t('search.navigation.title') }}
            </span>
            <span class="info-text">
              {{ $t('destination.all_destinations') }}
            </span>
          </div>
        </div>
      </template>
      <template #modal-title>
        <div
          class="container-title"
        >
          <UiElementsButton
            v-show="displaySearchItem != 'mobile-modal'"
            :color-style="'empty'"
            @click="displaySearchItem = 'mobile-modal'"
          >
            <UiIconsArrowLeft />
          </UiElementsButton>
          <div
            v-if="displaySearchItem === 'navigation'"
            class="info"
          >
            <span class="info-title">{{ $t('search.navigation.title') }}</span>
            <span class="info-text">
              {{ selectedDestinations.length > 0 ? selectedDestinations.join(',') : $t('search.navigation.text') }}
            </span>
          </div>
          <div
            v-else-if="displaySearchItem === 'travel'"
            class="info"
          >
            <span class="info-title">{{ $t('search.travel.title') }}</span>
            <span class="info-text">{{ date.length == 0 ? $t('search.travel.text') : formatDate(date) }}</span>
          </div>
          <div
            v-else-if="displaySearchItem === 'company'"
            class="info"
          >
            <span class="info-title">{{ $t('search.company.title') }}</span>
            <span class="info-text">
              {{ selectedCruiselines.length > 0 ? selectedCruiselines.join(',') : $t('search.company.text') }}
            </span>
          </div>
          <div
            v-if="displaySearchItem === 'mobile-modal'"
            class="info"
          >
            <span class="like-h4">{{ $t('search.title') }}</span>
          </div>
        </div>
      </template>
      <template #modal-content>
        <div class="container-menu-search">
          <div
            :class="{ current: displaySearchItem === 'mobile-modal' }"
            class="search-item"
          >
            <div
              class="search-btn"
              :class="{ current: displaySearchItem === 'navigation' }"
              @click="displaySearchItem = 'navigation'"
            >
              <div class="icon">
                <UiIconsEarth />
              </div>
              <div class="info">
                <span class="info-title">
                  {{ $t('search.navigation.title') }}
                </span>
                <span class="info-text">
                  {{ selectedDestinations.length > 0 ? selectedDestinations.join(',') : $t('search.navigation.text') }}
                </span>
              </div>
              <UiIconsArrowRight class="arrow" />
            </div>
          </div>
          <div class="search-item">
            <div
              class="search-btn"
              :class="{ current: displaySearchItem === 'travel' }"
              @click="displaySearchItem = 'travel'"
            >
              <div class="icon">
                <UiIconsDate />
              </div>
              <div class="info">
                <span class="info-title">{{ $t('search.travel.title') }}</span>
                <span class="info-text">{{ date.length > 1 ? formatDate(date) : $t('search.travel.text') }}</span>
              </div>
              <UiIconsArrowRight class="arrow" />
            </div>
          </div>
          <div class="search-item">
            <div
              class="search-btn"
              :class="{ current: displaySearchItem === 'company' }"
              @click="displaySearchItem = 'company'"
            >
              <div class="icon">
                <UiIconsBoat />
              </div>
              <div class="info">
                <span class="info-title">{{ $t('search.company.title') }}</span>
                <span class="info-text">
                  {{ selectedCruiselines.length > 0 ? selectedCruiselines.join(',') : $t('search.company.text') }}
                </span>
              </div>
              <UiIconsArrowRight class="arrow" />
            </div>
          </div>
          <div
            class="search-dropdown"
            :class="{ open: displaySearchItem === 'navigation' }"
          >
            <LazySearchElementsDestination
              v-model="selectedDestinations"
              :destinations-content="destinationsContent"
              :available-destinations="availableDestinations"
            />
          </div>
          <div
            class="search-dropdown"
            :class="{ open: displaySearchItem === 'travel' }"
          >
            <LazyFilterDatePicker
              v-model="date"
              class="date"
              :month-only="true"
              :available-departure-date="availableDepartureDate"
            />
          </div>
          <div
            class="search-dropdown"
            :class="{ open: displaySearchItem === 'company' }"
          >
            <LazySearchElementsCompany
              v-model="selectedCruiselines"
              :available-cruiselines="availableCruiselines"
            />
          </div>
        </div>
      </template>
      <template #custom-btn>
        <div class="container-btn">
          <div class="next">
            <UiElementsButton
              v-show="displaySearchItem != 'mobile-modal' && displaySearchItem != 'company'"
              :title="$t('btn.next')"
              :size="'large'"
              :color-style="'outline'"
              :variant="'light'"
              @click="nextFilter"
            >
              {{ $t('btn.next') }}
            </UiElementsButton>
          </div>
          <UiElementsButton
            :title="$t('btn.reset')"
            :size="'large'"
            :color-style="'outline'"
            :variant="'light'"
            @click="resetSearch"
          >
            {{ $t('btn.reset') }}
          </UiElementsButton>
          <UiElementsButton
            :color-style="'gradiant'"
            :size="'large'"
            :to="{ name: 'search', query: dynamicQueryFormat }"
          >
            {{ $t('header.menu.search') }}
          </UiElementsButton>
        </div>
      </template>
    </UiElementsButtonModal>
    <div class="search-action">
      <UiElementsButton
        :color-style="'gradiant'"
        :size="'large'"
        :to="{ name: 'search', query: dynamicQueryFormat }"
      >
        <UiIconsSearch/>
      </UiElementsButton>
    </div>
  </div>
  <div
    v-else
    v-click-outside="handleClickOutside"
    class="search-container container"
  >
    <div
      class="search-item"
      :class="{ current: displaySearchItem === 'navigation' }"
    >
      <div
        class="search-btn"
        :class="{ current: displaySearchItem === 'navigation' }"
        @click="displaySearchItem = 'navigation'"
      >
        <div class="icon">
          <UiIconsEarth />
        </div>
        <div class="info">
          <span class="info-title">{{ $t('search.navigation.title') }}</span>
          <span class="info-text">
            {{ selectedDestinations.length > 0 ? selectedDestinations.join(',') : $t('search.navigation.text') }}
          </span>
        </div>
        <UiIconsArrowDown class="arrow" />
      </div>
      <div
        class="search-dropdown"
        :class="{ open: displaySearchItem === 'navigation' }"
      >
        <LazySearchElementsDestination
          v-model="selectedDestinations"
          :destinations-content="destinationsContent"
          :available-destinations="availableDestinations"
        />
        <div class="filter-content--next">
          <UiElementsButton
            :title="$t('btn.reset')"
            :size="'large'"
            :color-style="'outline'"
            :variant="'light'"
            @click="resetSearch('destinations')"
          >
            {{ $t('btn.reset') }}
          </UiElementsButton>
          <UiElementsButton
            :variant="'light'"
            :size="'medium'"
            :color-style="'gradiant'"
            @click="nextFilter"
          >
            {{ $t('filter.button.next') }}
          </UiElementsButton>
        </div>
      </div>
    </div>
    <div
      class="search-item"
      :class="{ current: displaySearchItem === 'travel' }"
    >
      <div
        class="search-btn"
        :class="{ current: displaySearchItem === 'travel' }"
        @click="displaySearchItem = 'travel'"
      >
        <div class="icon">
          <UiIconsDate />
        </div>
        <div class="info">
          <span class="info-title">{{ $t('search.travel.title') }}</span>
          <span class="info-text">{{ date.length == 0 ? $t('search.travel.text') : formatDate(date) }}</span>
        </div>
        <UiIconsArrowDown class="arrow" />
      </div>
      <div
        class="search-dropdown"
        :class="{ open: displaySearchItem === 'travel' }"
      >
        <LazyFilterDatePicker
          v-model="date"
          class="date"
          month-only="true"
          :available-departure-date="availableDepartureDate"
        />
        <div class="filter-content--next">
          <UiElementsButton
            :title="$t('btn.reset')"
            :size="'large'"
            :color-style="'outline'"
            :variant="'light'"
            @click="resetSearch('date')"
          >
            {{ $t('btn.reset') }}
          </UiElementsButton>

          <UiElementsButton
            :variant="'light'"
            :size="'medium'"
            :color-style="'gradiant'"
            @click="nextFilter"
          >
            {{ $t('filter.button.next') }}
          </UiElementsButton>
        </div>
      </div>
    </div>
    <div
      class="search-item"
      :class="{ current: displaySearchItem === 'company' }"
    >
      <div
        class="search-btn"
        :class="{ current: displaySearchItem === 'company' }"
        @click="displaySearchItem = 'company'"
      >
        <div class="icon">
          <UiIconsBoat />
        </div>
        <div class="info">
          <span class="info-title">{{ $t('search.company.title') }}</span>
          <span class="info-text">
            {{ selectedCruiselines.length > 0 ? selectedCruiselines.join(',') : $t('search.company.text') }}
          </span>
        </div>
        <UiIconsArrowDown class="arrow" />
      </div>
      <div
        class="search-dropdown"
        :class="{ open: displaySearchItem === 'company' }"
      >
        <LazySearchElementsCompany
          :available-cruiselines="availableCruiselines"
          v-model="selectedCruiselines"
        />
        <div class="filter-content--next">
          <UiElementsButton
            :title="$t('btn.reset')"
            :size="'large'"
            :color-style="'outline'"
            :variant="'light'"
            @click="resetSearch('cruiselines')"
          >
            {{ $t('btn.reset') }}
          </UiElementsButton>
        </div>
      </div>
    </div>
    <div class="search-action">
      <UiElementsButton
        :color-style="'gradiant'"
        :size="'large'"
        :to="{ name: 'search', query: dynamicQueryFormat }"
      >
        {{ $t('header.menu.search') }}
      </UiElementsButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container-menu-search {
  display: flex;
  flex-direction: column;
}

.search-container {
  position: absolute;
  left: 50%;
  bottom: -2rem;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: calc(100% - 2rem);
  box-shadow: 0 4px 40px 0 #000E5526;
  z-index: 1;

  .cruise-link-mobile {
    display: flex;
    width: 100%;
    text-decoration: none;
  }

  @media (min-width: map-get($grid-breakpoints, md)) {
    width: calc(100% - 6rem);
  }

  .search-action {
    height: auto;

    > * {
      height: 100%;
    }
  }
}

.container-title {
  display: flex;
  align-items: center;
  width: calc(100% - 55px);

  .like-h4 {
    text-transform: initial;
    margin: 0;
  }

  button {
    padding: 24px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.info-text {
  word-break: break-word;
}

.info-title {
  text-transform: uppercase;
}

.search-container-mobile {
  .search-item {
    padding: $space-md $space-lg;
  }
}

.search-item {
  flex: 1;
  border-top: 1px solid var(--color-navy-150);
  background-color: var(--color-navy-0);

  @media (min-width: map-get($grid-breakpoints, md)) {
    border-top: none;
  }

  &:not(:last-of-type) {
    @media (min-width: map-get($grid-breakpoints, md)) {
      border-right: 1px solid var(--color-navy-150);
    }
  }

  &.current {
    @media (min-width: map-get($grid-breakpoints, md)) {
      box-shadow: 12px 0 15px -4px #000E5533, -12px 0 15px -4px #000E5533;
      z-index: 5;
      background-color: var(--color-white);
    }
  }

  .search-btn {
    padding: $space-md $space-lg;
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;

    .icon {
      width: 10%;
      flex: 0 0 10%;

      svg {
        height: 1.5em;
        width: 1.5em;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      width: 80%;
      flex: 0 0 80%;
    }

    .arrow {
      margin-left: auto;
      transition: transform 0.5s;
      width: 10%;
      flex: 0 0 10%;
    }

    &.current {
      .arrow {
        transform: rotate(180deg);
      }
    }
  }
}

.search-dropdown {
  background-color: var(--color-white);
  position: absolute;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  padding: $space-md $space-lg;
  z-index: 5;
  overflow-y: auto;

  @media (min-width: map-get($grid-breakpoints, md)) {
    overflow-y: hidden;
    height: auto;
    box-shadow: 0 4px 40px 0 #000E5526;
  }

  .filter-content--next {
    display: flex;
    justify-content: end;
    margin: 1em;
    gap: 1em;
  }

  &.open {
    position: absolute;
    top: 0;
    display: block;
    border-top: 1px solid var(--color-navy-150);

    @media (min-width: map-get($grid-breakpoints, md)) {
      margin-top: 98px;
    }
  }
}

.container-btn {
  position: sticky;
  z-index: 2;
  display: flex;
  gap: 1rem;
  padding: 2rem 1rem;
  box-shadow: 0 4px 24px 0 #00000033;
  flex-wrap: wrap;

  .next {
    width: 100%;
    button {
      width: 100%;
      flex: 0 0 100%
    }
  }

  a, button {
    flex: 1;
  }

  a {
    display: flex;
  }
}

:deep(.modal-btn-container) {
  display: flex;
  flex: 1;
}
</style>
