<script setup>
import delve from 'dlv'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import { sortArrayWithMapping } from '~/utils/formatter.js'
import { findPage } from '~/utils/findPage.js'

const { locale } = useI18n()

useHead({
  bodyAttrs: {
    class: 'sticky',
  },
})

const { data: page } = await useAsyncData(
  'index',
  () => findPage('homepage', locale.value, '', ['specialist', 'specialist.picture', 'specialist.specializations', 'specialization', 'header', 'header.background', 'header.mobile_background', 'destinations', 'destinations.background', 'event_block', 'event_block.picture', 'presentation']),
)

if (typeof page.value !== 'object' || !Object.keys(page.value).length) {
  throw createError({
    statusCode: 404,
    statusMessage: 'Page Not Found',
  })
}

const { data: destinations } = await useCachedAsyncData('destinations-page', () =>
  findPage('destinations-page', locale.value),
)

if (!Object.keys(destinations.value).length) {
  throw createError({
    statusCode: 404,
    statusMessage: 'Page Not Found',
  })
}

const destinationsSort = ['Méditerranée', 'Europe du Nord', 'Caraïbes', 'Iles Grecques', 'Croisières fluviales', 'Tour du Monde', 'France', 'Transocéaniques', 'Destinations polaires']

const destinationsContent = destinations.value.tender
const formattedDestinationsContent = sortArrayWithMapping(formatDestinations(destinationsContent), 'name', destinationsSort)
const formattedDestinationsForSlider = sortArrayWithMapping(formatDestinations(destinationsContent, false), 'name', destinationsSort)

if (delve(page.value, 'seo', false)) {
  setSeo(delve(page.value, 'seo'), delve(page.value, 'title', ''))
}

const faqsBlocks = delve(page.value, 'faqs_block')
const eventBlock = delve(page.value, 'event_block')

const header = delve(page.value, 'header', {})
const specialist = delve(page.value, 'specialist', {})
const specializations = formatSpecialization(delve(specialist, 'specializations.data', []))
const presentation = delve(page.value, 'presentation', {})
const favorites = delve(page.value, 'favorites', {})
</script>

<template>
  <div class="homepage">
    <HomeHeader
      v-if="header"
      :title="header.title"
      :subtitle="header.subtitle"
      :background-video-path="delve(header, 'background.data.attributes.url', '')"
      :mobile-background-image="delve(header, 'mobile_background.data.attributes.url', '')"
      :destinations-content="formattedDestinationsContent"
    />
    <LazyCruiseCrossSelling
      title="Vos coups de cœur"
      sub-title="Trouvez l’inspiration."
      :cruises="favorites"
    />

    <LazyHomeEventBlock
      v-if="eventBlock"
      :event-block="eventBlock"
    />
    <ReviewContainer />
    <LazyAboutTailorMade
      v-if="specialist"
      :title="specialist.title"
      :subtitle="specialist.subtitle"
      :picture="specialist.picture.data.attributes.url"
      :specializations="specializations"
      :display-unique-service="false"
      class="homepage"
    />
    <carousel
      class="homepage--carousel"
      :items-to-show="1"
      :breakpoints="{
        768: {
          itemsToShow: 1.3,
        },
      }"
      :wrap-around="true"
    >
      <slide
        v-for="destinationContent in formattedDestinationsForSlider"
        :key="destinationContent.id"
      >
        <HomeDestinationSlide
          :title="destinationContent.name"
          :children="destinationContent.children"
          :image="destinationContent.image"
          :slug="destinationContent.slug"
        />
      </slide>

      <template #addons>
        <navigation>
          <template #prev>
            <UiIconsArrowWithLine direction="left" />
          </template>
          <template #next>
            <UiIconsArrowWithLine direction="right" />
          </template>
        </navigation>
      </template>
    </carousel>

    <LazyHomePresentation
      v-if="presentation"
      :overlay-title="'Nos Conseillers vous attendent pour préparer votre croisière'"
      :overlay-description="'Partez en toute tranquillité avec Planète Croisière, votre conseiller prend en main toute l’organisation de votre croisière : administratif, itinéraires, logistique, réservations...'"
      :content-title="presentation.title"
      :content-description="presentation.description"
    />
    <LazyBlogBlock />

    <FaqFaqsBlock
      v-if="faqsBlocks"
      :faqs="faqsBlocks.faqs.data"
      :title="faqsBlocks.title"
      :subtitle="faqsBlocks.subtitle"
      :employee="faqsBlocks.employee"
      :cta-text="'Contactez-nous'"
      :cta-path="'contact'"
    />
  </div>
</template>

<style lang="scss">
.homepage {

  .homepage--carousel {
    .carousel__prev, .carousel__next {
      background: white;
      padding: .5em;
      font-size: 1em;
      top: 40%;

      @media (min-width: map-get($grid-breakpoints, md)) {
        top: 50%;
      }
    }

    .carousel__prev {
      left: calc(50% - 65px);

      @media (min-width: map-get($grid-breakpoints, md)) {
        left: 0;
      }
    }
    .carousel__next {
      right: calc(50% - 65px);

      @media (min-width: map-get($grid-breakpoints, md)) {
        right: 0;
      }
    }
  }

}
</style>
